/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequest = /* GraphQL */ `
  query GetRequest($traceId: String!) {
    getRequest(traceId: $traceId) {
      hashKey
      sortKey
      traceId
      errorCode
      errorDescription
      sourceId
      customsInventoryType
      epochTimestamp
      timestamp
      inventoryLocation
      source
      materialNumber
      sizeCode
      duration
      message
      errorMessage
      status
      businessReference
    }
  }
`;
export const getRequestDetails = /* GraphQL */ `
  query GetRequestDetails($traceId: String!, $logType: String!) {
    getRequestDetails(traceId: $traceId, logType: $logType) {
      items {
        hashKey
        sortKey
        timestamp
        componentName
        region
        message
        errorCode
        errorDescription
        errorMessage
        payloadRegion
        payloadBucket
        payloadPath
        payloadVersion
        properties
      }
    }
  }
`;
export const getPayload = /* GraphQL */ `
  query GetPayload(
    $region: String!
    $bucket: String!
    $key: String!
    $version: String!
  ) {
    getPayload(region: $region, bucket: $bucket, key: $key, version: $version) {
      content
      contentType
      preSignedUrl
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $epochTimestampFrom: String
    $epochTimestampTo: String
    $limit: Int
    $nextToken: String
  ) {
    listRequests(
      epochTimestampFrom: $epochTimestampFrom
      epochTimestampTo: $epochTimestampTo
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsDynamicSearch = /* GraphQL */ `
  query ListRequestsDynamicSearch(
    $customsInventoryType: String
    $errorCode: String
    $fromTimestamp: Float
    $inventoryLocation: String
    $messageType: String
    $offsetFromCurrentTimestamp: String
    $offsetToCurrentTimestamp: String
    $sortKey: String
    $source: String
    $sourceId: String
    $status: String
    $toTimestamp: Float
    $businessReference: String
  ) {
    listRequestsDynamicSearch(
      customsInventoryType: $customsInventoryType
      errorCode: $errorCode
      fromTimestamp: $fromTimestamp
      inventoryLocation: $inventoryLocation
      messageType: $messageType
      offsetFromCurrentTimestamp: $offsetFromCurrentTimestamp
      offsetToCurrentTimestamp: $offsetToCurrentTimestamp
      sortKey: $sortKey
      source: $source
      sourceId: $sourceId
      status: $status
      toTimestamp: $toTimestamp
      businessReference: $businessReference
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByInventoryLocation = /* GraphQL */ `
  query ListRequestsByInventoryLocation(
    $inventoryLocation: String
    $epochTimestampFrom: String
    $epochTimestampTo: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByInventoryLocation(
      inventoryLocation: $inventoryLocation
      epochTimestampFrom: $epochTimestampFrom
      epochTimestampTo: $epochTimestampTo
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsBySourceId = /* GraphQL */ `
  query ListRequestsBySourceId(
    $sourceId: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsBySourceId(
      sourceId: $sourceId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByType = /* GraphQL */ `
  query ListRequestsByType(
    $inventoryType: String
    $epochTimestampFrom: String
    $epochTimestampTo: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByType(
      inventoryType: $inventoryType
      epochTimestampFrom: $epochTimestampFrom
      epochTimestampTo: $epochTimestampTo
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByTypeAndInventoryLocation = /* GraphQL */ `
  query ListRequestsByTypeAndInventoryLocation(
    $inventoryType: String
    $inventoryLocation: String
    $filter: requestFilter
    $epochTimestampFrom: String
    $epochTimestampTo: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByTypeAndInventoryLocation(
      inventoryType: $inventoryType
      inventoryLocation: $inventoryLocation
      filter: $filter
      epochTimestampFrom: $epochTimestampFrom
      epochTimestampTo: $epochTimestampTo
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByErrorCode = /* GraphQL */ `
  query ListRequestsByErrorCode(
    $errorCode: String
    $epochTimestampFrom: String
    $epochTimestampTo: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByErrorCode(
      errorCode: $errorCode
      epochTimestampFrom: $epochTimestampFrom
      epochTimestampTo: $epochTimestampTo
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsAllErrors = /* GraphQL */ `
  query ListRequestsAllErrors($limit: Int, $nextToken: String) {
    listRequestsAllErrors(limit: $limit, nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByTypeInventoryLocationErrorCode = /* GraphQL */ `
  query ListRequestsByTypeInventoryLocationErrorCode(
    $sortKey: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByTypeInventoryLocationErrorCode(
      sortKey: $sortKey
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByTypeErrorCode = /* GraphQL */ `
  query ListRequestsByTypeErrorCode(
    $sortKey: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByTypeErrorCode(
      sortKey: $sortKey
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsByInventoryLocationErrorCode = /* GraphQL */ `
  query ListRequestsByInventoryLocationErrorCode(
    $sortKey: String
    $limit: Int
    $nextToken: String
  ) {
    listRequestsByInventoryLocationErrorCode(
      sortKey: $sortKey
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
export const listRequestsBySku = /* GraphQL */ `
  query ListRequestsBySku($sortKey: String, $limit: Int, $nextToken: String) {
    listRequestsBySku(sortKey: $sortKey, limit: $limit, nextToken: $nextToken) {
      items {
        hashKey
        sortKey
        traceId
        errorCode
        errorDescription
        sourceId
        customsInventoryType
        epochTimestamp
        timestamp
        inventoryLocation
        source
        materialNumber
        sizeCode
        duration
        message
        errorMessage
        status
        businessReference
      }
      nextToken
    }
  }
`;
