import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    getRequest,
    getRequestDetails,
    getPayload,
    listRequestsDynamicSearch
} from "../../graphql/queries";

export class StructuredLogsService {

    constructor(private appsyncService: AppsyncService) {
    }

    async getRequest(traceId: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getRequest), fetchPolicy: 'network-only', variables: {
                    traceId: traceId
                }
            }
        );
    }

    async getPayload(region: string, bucket: string, key: string, version: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getPayload),
                fetchPolicy: 'network-only',
                variables: {
                    region: region,
                    bucket: bucket,
                    key: key,
                    version: version
                }
            }
        );
    }

    async getRequestDetails(traceId: string, logType: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getRequestDetails), fetchPolicy: 'network-only', variables: {
                    traceId: traceId,
                    logType: logType
                }
            }
        );
    }

    async listRequests(nextToken: string, source: string, sourceId: string, status: string, inventoryType: string,
                       inventoryLocation: string, errorCode: string, startDate: Date, endDate: Date, businessReference: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listRequestsDynamicSearch), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                    messageType: 'CustomsInventoryRequest',
                    source: source != null ? source : null,
                    sourceId: sourceId != null ? sourceId : null,
                    businessReference: businessReference != null ? businessReference : null,
                    status: status != null ? status : null,
                    customsInventoryType: inventoryType != null ? inventoryType : null,
                    inventoryLocation: inventoryLocation != null ? inventoryLocation : null,
                    errorCode: errorCode != null ? errorCode : null,
                    fromTimestamp: startDate != null ? startDate.getTime() : null,
                    toTimestamp: endDate != null ? endDate.getTime() : null
                }
            }
        );
    }

}